import type { NextPage } from "next";
import { PopUpT as Props } from "@/types/popup";
import { en as locale } from "@/helpers/translationTexts";

import BaseHeader from "@/components/BaseHeader";
import Login from "@/components/Login";

const Page: NextPage<Props> = () => {
  return (
    <div>
      <BaseHeader title={locale.loginMetaTitle} description={locale.loginMetaDescription} />
      <Login />
    </div>
  );
};

export default Page;
