import Container from "../Container";
import useTranslation, { useRoutes } from "../../helpers/translationTexts/useTranslation";
import { AuthBox, Title, LoginFacebookButton, LoginGoogleButton, GoBackButton } from "./Login.components";
import { useSession } from "../../context/SessionProvider";
import { useEffect } from "react";
import Router from "next/router";

const Login: React.FC = () => {
  const { userData } = useSession();
  const isAuthorized = userData && userData.nickname && userData.countryCode;
  const { Root: rootPath, Registration: registrationPath, Login: loginPath } = useRoutes();

  useEffect(() => {
    if (!userData) {
      Router.push(loginPath);
    } else if (isAuthorized) {
      Router.push(rootPath);
    } else {
      Router.push(registrationPath);
    }
  }, [isAuthorized, loginPath, registrationPath, rootPath, userData]);

  return (
    <Container>
      <AuthBox>
        <Title>{useTranslation("welcomeBack")}</Title>
        <LoginGoogleButton>{useTranslation("continueWithGoogle")}</LoginGoogleButton>
        <LoginFacebookButton>{useTranslation("continueWithFacebook")}</LoginFacebookButton>
        <GoBackButton>{useTranslation("goBack")}</GoBackButton>
      </AuthBox>
    </Container>
  );
};

export default Login;
